<template>
    <b-row>
        <b-col sm="12">
            <b-form-group :label="$t('semester')">
                <div class="label-as-input">
                    {{ getSemester(form.semester) }}
                </div>
            </b-form-group>
        </b-col>
        <b-col sm="12">
            <b-form-group :label="$t('type')">
                <div class="label-as-input">
                    {{ form.type_name }}
                </div>
            </b-form-group>
        </b-col>
        <b-col sm="12">
            <b-form-group :label="$t('start_date')">
                <div class="label-as-input">
                    {{ toLocaleDate(form.start_date) }}
                </div>
            </b-form-group>
        </b-col>
        <b-col sm="12">
            <b-form-group :label="$t('end_date')">
                <div class="label-as-input">
                    {{ toLocaleDate(form.end_date) }}
                </div>
            </b-form-group>
        </b-col>
    </b-row>
</template>

<script>
import SemesterDateService from "@/services/SemesterDateService";

export default{
    props:{
        id:{
            type:Number,
            default:null
        }
    },
    data(){
      return {
          form:{}
      }
    },
    created() {
        this.show()
    },
    methods:{
        show(){
            SemesterDateService.get(this.id)
                .then((response) => {
                    this.form = response.data.data;
                })
                .catch((error) => {
                    if (error.data.message) {
                        this.$toast.error(this.$t("api." + error.data.message));
                    }
                });
        }
    }
}
</script>
